<template>
  <div class="outer-wrapper">
    <div class="title-wrapper">
      <span>安评风险点管理</span>
    </div>
    <div class="inner-wrapper">
      <div class="editor-wrapper">
        <Tabs>
          <TabPane label="现有风险点" icon="ios-warning">
            <query-table
              :columnsFormatData="columnsFormatData"
              askData="resultRiskManage"
              :refreshData="update_count"
              main_width="750px"
            ></query-table>
          </TabPane>
        </Tabs>
      </div>
      <div class="preview-wrapper">
        <Tabs>
          <TabPane label="新增风险点" icon="ios-add-circle-outline">
            <div class="form-wrapper">
              <Form
                ref="addRisk"
                :model="newRisk"
                :rules="ruleValidate"
                :label-width="150"
              >
                <FormItem label="请选择线路：" prop="line_code">
                  <Select  v-model="newRisk.line_code" style="width: 260px">
                      <Option
                      v-for="item in activeOption.lineChoices"
                      :value="item.line_code"
                      :key="item.line_code"
                      >{{ item.line_name }}</Option
                    >
                  </Select>
                </FormItem>
                <FormItem label="风险点概述：" prop="risk_content">
                  <Input v-model="newRisk.risk_content" placeholder="" />
                </FormItem>
                <FormItem label="风险点详情说明：" prop="description">
                  <Input
                    v-model="newRisk.description"
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 5 }"
                    placeholder="Enter something..."
                  />
                </FormItem>
                <FormItem label="评价年度：" prop="evaluate_year">
                  <DatePicker
                    type="year"
                    placeholder="选择年份"
                    style="width: 200px"
                    v-model="newRisk.evaluate_year"
                  ></DatePicker>
                </FormItem>
                <FormItem label="类别：" prop="sort">
                  <Select
                    v-model="newRisk.sort"
                    multiple
                    style="width: 260px"
                  >
                    <Option
                      v-for="item in option.sortChoices"
                      :value="item.value"
                      :key="item.value"
                      >{{ item.label }}</Option
                    >
                  </Select>
                </FormItem>
                <FormItem label="分值：" prop="score">
                  <InputNumber
                    :max="100"
                    :min="1"
                    v-model="newRisk.score"
                  ></InputNumber>
                </FormItem>
                <FormItem label="所属专业：" prop="major_system">
                  <Select
                    v-model="newRisk.major_system"
                    multiple
                    style="width: 260px"
                  >
                    <Option
                      v-for="item in option.majorSystemChoices"
                      :value="item.value"
                      :key="item.value"
                      >{{ item.label }}</Option
                    >
                  </Select>
                </FormItem>
                <FormItem label="专业子系统：" prop="involve_interface">
                  <Select
                    v-model="newRisk.involve_interface"
                    multiple
                    style="width: 260px"
                  >
                    <Option
                      v-for="item in option.interfaceChoices"
                      :value="item.value"
                      :key="item.value"
                      >{{ item.label }}</Option
                    >
                  </Select>
                </FormItem>
                <FormItem label="严重等级：" prop="degree_level">
                  <Select v-model="newRisk.degree_level" style="width: 260px">
                    <Option
                      v-for="item in option.degreeLevelChoices"
                      :value="item.value"
                      :key="item.value"
                      >{{ item.label }}</Option
                    >
                  </Select>
                </FormItem>
                <FormItem label="整改要求：" prop="correct_demand">
                  <Select v-model="newRisk.correct_demand" style="width: 260px">
                    <Option
                      v-for="item in option.correctDemandChoices"
                      :value="item.value"
                      :key="item.value"
                      >{{ item.label }}</Option
                    >
                  </Select>
                </FormItem>
                <FormItem label="对策建议：" prop="solution_proposal">
                  <Input
                    v-model="newRisk.solution_proposal"
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 5 }"
                    placeholder="Enter something..."
                  />
                </FormItem>
                <FormItem label="归档编号：" prop="archive_no">
                  <Input v-model="newRisk.archive_no" placeholder="" />
                </FormItem>
                <FormItem>
                  <Button type="primary" @click="handleSubmit('addRisk')"
                    >提交</Button
                  >
                  <Button
                    @click="handleReset('addRisk')"
                    style="margin-left: 8px"
                    >重置</Button
                  >
                </FormItem>
              </Form>
              <Spin fix v-show="loadingStatus"></Spin>
            </div>
          </TabPane>
          <TabPane label="批量添加" icon="md-cloud-upload">
            <div class="form-wrapper">
              <div class="title-wrapper">
                <p>
                  请上传包含安评风险点相关数据的excel文件：
                  <a href="/public/templates/RiskTemplate.xlsx"
                    >(模板文件下载)</a
                  >
                </p>
              </div>
              <file-upload
                target="mulityByExcel"
                iurl="/result_risk_alter"
                @handleSuccess="()=>this.update_count++"
              ></file-upload>
            </div>
          </TabPane>
        </Tabs>
      </div>
    </div>

    <Modal
      v-model="scModal"
      width="800"
      :mask-closable="false"
      @on-cancel="modal_cancel"
    >
      <p slot="header" class="sc-header">
        <Icon type="ios-list-box" />
        <span>风险点信息修改</span>
      </p>
      <div class="sc-content-wrapper">
          <div class="form-wrapper">
              <Form
                ref="alterRisk"
                :model="currentRisk"
                :rules="ruleValidate"
                :label-width="150"
              >
                <FormItem label="请选择线路：" prop="line">
                  <Select  v-model="currentRisk.line_code" style="width: 260px">
                      <Option
                      v-for="item in activeOption.lineChoices"
                      :value="item.line_code"
                      :key="item.line_code"
                      >{{ item.line_name }}</Option
                    >
                  </Select>
                </FormItem>
                <FormItem label="风险点概述：" prop="risk_content">
                  <Input v-model="currentRisk.risk_content" placeholder="" />
                </FormItem>
                <FormItem label="风险点详情说明：" prop="description">
                  <Input
                    v-model="currentRisk.description"
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 5 }"
                    placeholder="Enter something..."
                  />
                </FormItem>
                <FormItem label="评价年度：" prop="evaluate_year">
                  <DatePicker
                    type="year"
                    placeholder="请选择年份"
                    style="width: 200px"
                    v-model="currentRisk.evaluate_year"
                  ></DatePicker>
                </FormItem>
                <FormItem label="类别：" prop="sort">
                  <Select
                    v-model="currentRisk.sort"
                    multiple
                    style="width: 260px"
                  >
                    <Option
                      v-for="item in option.sortChoices"
                      :value="item.value"
                      :key="item.value"
                      >{{ item.label }}</Option
                    >
                  </Select>
                </FormItem>
                <FormItem label="分值：" prop="score">
                  <InputNumber
                    :max="100"
                    :min="1"
                    v-model="currentRisk.score"
                  ></InputNumber>
                </FormItem>
                <FormItem label="所属专业：" prop="major_system">
                  <Select
                    v-model="currentRisk.major_system"
                    multiple
                    style="width: 260px"
                  >
                    <Option
                      v-for="item in option.majorSystemChoices"
                      :value="item.value"
                      :key="item.value"
                      >{{ item.label }}</Option
                    >
                  </Select>
                </FormItem>
                <FormItem label="专业子系统：" prop="involve_interface">
                  <Select
                    v-model="currentRisk.involve_interface"
                    multiple
                    style="width: 260px"
                  >
                    <Option
                      v-for="item in option.interfaceChoices"
                      :value="item.value"
                      :key="item.value"
                      >{{ item.label }}</Option
                    >
                  </Select>
                </FormItem>
                <FormItem label="严重等级：" prop="degree_level">
                  <Select v-model="currentRisk.degree_level" style="width: 260px">
                    <Option
                      v-for="item in option.degreeLevelChoices"
                      :value="item.value"
                      :key="item.value"
                      >{{ item.label }}</Option
                    >
                  </Select>
                </FormItem>
                <FormItem label="整改要求：" prop="correct_demand">
                  <Select v-model="currentRisk.correct_demand" style="width: 260px">
                    <Option
                      v-for="item in option.correctDemandChoices"
                      :value="item.value"
                      :key="item.value"
                      >{{ item.label }}</Option
                    >
                  </Select>
                </FormItem>
                <FormItem label="解决方案：" prop="solution_proposal">
                  <Input
                    v-model="currentRisk.solution_proposal"
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 5 }"
                    placeholder="Enter something..."
                  />
                </FormItem>
                <FormItem label="归档编号：" prop="archive_no">
                  <Input v-model="currentRisk.archive_no" placeholder="" />
                </FormItem>
              </Form>
          </div>
      </div>
      <div slot="footer">
        <Button size="large" @click="modal_save('alterRisk')">确定</Button>
        <Button size="large" @click="modal_cancel">取消</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
import fileUpload from "@/components/Common/FileUpload.vue";
import queryTable from "@/components/Common/QueryTable.vue";

let userToken = localStorage.getItem("current_user_token");

function riskFac() {
  return {
    id:0,
    line_code:'',
    line_name:'',
    risk_content:'',
    evaluate_year:'',
    score:0,
    sort:'',
    description:'',
    degree_level:'',
    correct_demand:'',
    solution_proposal:'',
    involve_interface:'',
    major_system:'',
    archive_no:'',
  };
}

export default {
  name: "resultRiskManage",
  components: {
    queryTable,
    fileUpload,
  },
  props: {},
  data() {
    return {
      update_count: 0,
      columnsFormatData: [
        {
          title: "风险点名称",
          key: "risk_content",
          align: "center",
        },

        {
          title: "线路名称",
          key: "line_name",
          align: "center",
          width: 120,
        },
        {
          title: "操作",
          align: "center",
          width: 130,
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { color: "green" },
                  on: {
                    click: () => {
                      this.itemEdit(params.row);
                    },
                  },
                },
                "修改"
              ),
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { color: "red", marginLeft: "5px" },
                  on: {
                    click: () => {
                      this.confirmBox(params.row);
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      loadingStatus: false,
      newRisk: riskFac(),
      currentRisk:riskFac(),
      ruleValidate: {
        line_code: [
          {
            required: true,
            type: "string",
            message: "请选择线路！",
            trigger: "blur",
          },
        ],
        risk_content: [
          {
            required: true,
            type: "string",
            message: "请输入风险点概况！",
            trigger: "blur",
          },
        ],
        description: [
          {
            required: true,
            type: "string",
            message: "请输入风险点详情！",
            trigger: "blur",
          },
        ],
        evaluate_year: [
          {
            required: true,
            type: "date",
            message: "请选择发生时间！",
            trigger: "blur",
          },
        ],
        sort: [
          {
            required: true,
            type: "array",
            message: "请选择类别！",
            trigger: "blur",
          },
        ],
        involve_interface: [
          {
            required: true,
            type: "array",
            message: "请选择专业系统！",
            trigger: "blur",
          },
        ],
        major_system: [
          {
            required: true,
            type: "array",
            message: "请选择所属专业！",
            trigger: "blur",
          },
        ],
        score: [
          { type: "number", min: 1, message: "请输入分数！", trigger: "blur" },
        ],
        degree_level: [
          {
            required: true,
            type: "string",
            message: "请选择严重等级！",
            trigger: "blur",
          },
        ],
        correct_demand: [
          {
            required: true,
            type: "string",
            message: "请选择整改要求！",
            trigger: "blur",
          },
        ],
        solution_proposal: [
          {
            required: true,
            type: "string",
            message: "请输入解决方案！",
            trigger: "blur",
          },
        ],
        archive_no: [
          { type: "string", max: 20, message: "不超过20个字符！", trigger: "change" },
        ],
      },
      option:{
        sortChoices: [
        {
          value: "A",
          label: "A",
        },
        {
          value: "B",
          label: "B",
        },
        {
          value: "C",
          label: "C",
        },
        {
          value: "D",
          label: "D",
        },
        {
          value: "E",
          label: "E",
        },
      ],
      interfaceChoices: [
        {
          label: "供电",
          value: "供电",
        },
        {
          label: "土建",
          value: "土建",
        },
        {
          label: "信号",
          value: "信号",
        },
        {
          label: "车辆",
          value: "车辆",
        },
        {
          label: "通信",
          value: "通信",
        },
        {
          label: "安全",
          value: "安全",
        },
        {
          label: "机电",
          value: "机电",
        },
        {
          label: "运营",
          value: "运营",
        },
        {
          label: "消防",
          value: "消防",
        },
      ],
      majorSystemChoices:[

      ],
      degreeLevelChoices: [
        {
          label: "灾难性",
          value: "灾难性",
        },
        {
          label: "非常严重",
          value: "非常严重",
        },
        {
          label: "严重",
          value: "严重",
        },
        {
          label: "可接受",
          value: "可接受",
        },
        {
          label: "需重视",
          value: "需重视",
        },
      ],
      correctDemandChoices: [
        {
          label: "计划整改",
          value: "计划整改",
        },
        {
          label: "急需整改",
          value: "急需整改",
        },
        {
          label: "其他",
          value: "/",
        },
      ],
      },
      activeOption:{

      },
      scModal: false,
    };
  },
  watch: {},
  computed: {},
  created() {
    this.getStaticOption();
    this.getActiveOption();
  },
  methods: {
    getStaticOption() {
      this.$axios
        .post(this.$url + "/static_option", {
          userToken: userToken,
          askData: "resultRiskManage",
        })
        .then((response) => {
            this.option = response.data.res_record;
        });
    },
     getActiveOption() {
    this.$axios
        .post(this.$url + "/active_option", {
          userToken: userToken,
          askData: "lineList",
        })
        .then((response) => {
          this.activeOption = {'lineChoices':response.data.res_record};
        });
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          let form = new FormData();
          form.append("userToken", userToken);
          form.append("askData", "addRisk");
          form.append("newRisk", JSON.stringify(this.newRisk));
          this.loadingStatus = true;
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          this.$axios
            .post(this.$url + "/result_risk_alter", form, config)
            .then((response) => {
              this.loadingStatus = false;
              if (response.data.stat === "ok") {
                this.$Message.success("风险点添加成功！");
                this.update_count++;
                this.handleReset(name);
              } else {
                this.$Message.error(response.data.error_info);
              }
            })
            .catch((error) => {
              this.loadingStatus = false;
              this.$Message.error(error + "未知错误！");
            });
        } else {
          this.$Message.error("填入信息错误!");
        }
      });
    },
    modal_save(name) {
        this.$refs[name].validate((valid) => {
        if (valid) {
          let form = new FormData();
          form.append("userToken", userToken);
          form.append("askData", "alterRisk");
          form.append("currentRisk", JSON.stringify(this.currentRisk));
          this.loadingStatus = true;
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          this.$axios
            .post(this.$url + "/result_risk_alter", form, config)
            .then((response) => {
              this.loadingStatus = false;
              if (response.data.stat === "ok") {
                this.$Message.success("风险点修改成功！");
                this.update_count++;
                this.scModal = false;
              } else {
                this.$Message.error(response.data.error_info);
              }
            })
            .catch((error) => {
              this.loadingStatus = false;
              this.$Message.error(error + "未知错误！");
            });
        } else {
          this.$Message.error("填入信息错误!");
        }
      });
    },
    modal_cancel() {
      this.scModal = false;
    },
    confirmBox(value) {
      this.$Modal.confirm({
        title: "确认删除该条风险点信息：",
        content:
          "<p>" +
          "所属线路：" +
          value.line_name +
          "</p>" +
          "<p>" +
          "风险点简要：" +
          value.risk_content +
          "</p>",
        onOk: () => {
          this.delItem(value.id);
        },
        onCancel: () => {},
      });
    },
    delItem(id) {
      this.$axios
        .post(this.$url + "/result_risk_alter", {
          userToken: userToken,
          askData: "delRisk",
          pid: id,
        })
        .then((response) => {
          if (response.data.stat === "ok") {
            this.$Message.success("删除成功！");
            this.update_count++;
          }
        })
        .catch((error) => {
          this.$Message.warning(error + "网络错误！操作失败。");
        });
    },
    itemEdit(value){
        this.currentRisk = riskFac();
        this.currentRisk.id = value.id;
        this.currentRisk.line_code = value.line_code;
        this.currentRisk.line_name = value.line_name;
        this.currentRisk.risk_content = value.risk_content;
        this.currentRisk.evaluate_year = value.evaluate_year;
        this.currentRisk.score = value.score;
        this.currentRisk.sort = value.sort;
        this.currentRisk.description = value.description;
        this.currentRisk.degree_level = value.degree_level;
        this.currentRisk.correct_demand = value.correct_demand;
        this.currentRisk.solution_proposal = value.solution_proposal;
        this.currentRisk.involve_interface = value.involve_interface;
        this.currentRisk.major_system = value.major_system;
        this.currentRisk.archive_no = value.archive_no;
        this.scModal = true;
    }
  },
};
</script>
<style scoped>
.inner-wrapper {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  min-height: 700px;
}

.title-wrapper {
  text-align: center;
  font-size: 20px;
  padding: 16px;
}

.editor-wrapper,
.preview-wrapper {
  margin: 0px 20px 0px;
  width: 750px;
  font-size: 14px;
  text-align: center;
  padding: 10px 0;
}

.form-wrapper {
  text-align: left;
  border: 1px solid #eee;
  padding: 80px 120px 0px 50px;
  min-height: 600px;
  position: relative;
}

.sc-content-wrapper {
  padding: 0 50px;
  color: #333;
  font-size: 14px;
}
.choosed-product-wrapper {
  min-height: 100px;
  padding: 10px 20px;
  border: 1px solid #eee;
  box-shadow: 1px 1px 1px #eee;
  margin: 20px 0;
}

.sc-content-wrapper td:first-child {
  text-align: right;
  padding-right: 10px;
}

.sc-content-wrapper td {
  height: 45px;
  color: #777;
}

.sub-content-wrapper {
  margin: 10px 0;
  border: 1px solid #eee;
  position: relative;
}

.sub-content-title {
  position: absolute;
  left: 0;
  overflow: hidden;
  height: 100%;
  writing-mode: vertical-lr;
  text-align: center;
  background: #f5f5f5;
  letter-spacing: 10px;
  text-indent: 10px;
  padding: 12px 10px;
}

.table-wrapper {
  padding: 22.5px 20px;
  margin-left: 50px;
}

.fast-button {
  cursor: pointer;
}

input::-webkit-input-placeholder {
  color: #ccc;
  text-align: center;
}

input:-ms-input-placeholder {
  color: #ccc;
  text-align: center;
}

input::-moz-placeholder {
  color: #ccc;
  text-align: center;
}
tr.modal-product-choosed-tr {
  border-bottom: 1px solid #ddd;
}

tr.modal-product-choosed-tr:last-child {
  border: none;
}
</style>